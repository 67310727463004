<script setup>
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";

const navigation = {
  pages: [
    { id: "cupones", name: "Cuponera", href: "/#cuponera" },
    {
      id: "registrar-comercio",
      name: "Registrar comercio",
      href: "/web/registro-comercio",
    },
  ],
};

const open = ref(false);
</script>

<template>
  <div class="bg-white">
    <!-- Mobile menu -->
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-40 lg:hidden" @close="open = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-slate-200 pb-12 shadow-xl"
            >
              <div class="flex px-4 pb-2 pt-5">
                <button
                  type="button"
                  class="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                  @click="open = false"
                >
                  <span class="absolute -inset-0.5" />
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <!-- Links -->

              <div class="space-y-6 px-4 py-6">
                <div
                  v-for="page in navigation.pages"
                  :key="page.name"
                  class="flow-root"
                >
                  <NuxtLink
                    :to="page.href"
                    class="-m-2 block p-2 font-medium text-custom-fuchsia-07e hover:text-pink-700"
                    >{{ page.name }}</NuxtLink
                  >
                </div>
              </div>

              <!-- <div class="space-y-6 border-t border-gray-200 px-4 py-6">
                <div class="flow-root">
                  <NuxtLink
                    v-if="!user"
                    to="/auth/signin"
                    class="-m-2 block p-2 font-medium text-gray-900"
                    >Acceder</NuxtLink
                  >
                  <div v-else class="font-medium text-gray-900">
                    {{ user && `Hola ${user?.user_metadata?.firstname} !` }}
                  </div>
                </div>
                <div class="flow-root">
                  <NuxtLink
                    v-if="!user"
                    to="/auth/signup"
                    class="-m-2 block p-2 font-medium text-gray-900"
                    >Crear cuenta</NuxtLink
                  >
                   <Button
                    v-else
                    type="button"
                    class="-m-2 block pl-2 text-sm text-gray-700 hover:text-gray-800"
                    @click="signOut"
                    >Cerrar sesión</Button
                  > 
                </div>
              </div> -->
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <header class="relative bg-white">
      <p
        class="flex h-10 items-center justify-center bg-custom-fuchsia-07e px-4 text-sm font-medium text-white sm:px-6 lg:px-8"
      >
        Ahora registra gratis tu comercio en Chicupón!
      </p>

      <nav aria-label="Top" class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between">
          <div class="flex h-16 items-center">
            <button
              type="button"
              class="relative rounded-md bg-white p-2 text-gray-400 lg:hidden"
              @click="open = true"
            >
              <span class="absolute -inset-0.5" />
              <span class="sr-only">Open menu</span>
              <Bars3Icon class="h-6 w-6" aria-hidden="true" />
            </button>

            <!-- Logo -->
            <div class="ml-4 flex lg:ml-0">
              <NuxtLink to="/">
                <span class="sr-only">Chicupón</span>
                <img class="h-10 w-auto" src="/logo.png" alt="" />
              </NuxtLink>
            </div>
          </div>

          <!-- Flyout menus -->
          <div class="hidden lg:ml-8 lg:block lg:self-stretch">
            <div class="flex h-full space-x-8">
              <NuxtLink
                v-for="page in navigation.pages"
                :key="page.name"
                :to="page.href"
                class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                >{{ page.name }}</NuxtLink
              >
            </div>
          </div>
        </div>
      </nav>
    </header>
    <slot />
  </div>
</template>
